import { React } from "react";
import successCheck from '../../assets/gif/success_check.gif';
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { apiCall } from "../../Components/Helper/ApiHelper";
import Constant, { storage } from "../../config/Constant";

const Success = () => {
    
    const navigate = useNavigate()

    useEffect(() => {
        const id = localStorage?.getItem(storage?.stripeId)
        const body = {
            status:1,
            id
        }
        if (id > 0) {
            apiCall({method:'post', url:Constant?.apis?.walletTransactionStatus , body})?.then((res) => {
                if (res?.status) localStorage?.setItem(storage?.stripeId, 0)
            })
        }
    }, [])

    return (
        <div className="success-page">
            <div className="bg-white">
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="d-flex align-items-center justify-content-center flex-column p-5 text-capitalize gap-4 m-5">
                                <div>
                                    <Image src={successCheck} width={100} />
                                </div>
                                <div>
                                    <h2 className="text-grey-500 ff-poppins fw-600">recharge successful</h2>
                                </div>
                                <div>
                                    <Button
                                        className="primary-bg-color border-0 fw-600 text-dark"
                                        onClick={() => navigate('/recharge')}
                                    >
                                        Back To Wallet
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )    
}
export default Success;