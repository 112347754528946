import axios from "axios";
import moment from "moment/moment";

const baseURL = (['localhost', '192.168.0.136']?.includes(window?.location?.hostname)) ? `http://${window?.location?.hostname}:${window?.location?.port}/` : "https://astroguide4u.com/";

export const apiBaseURL = 'https://astroguide4u.com:9000/';
export const apiURL = 'https://astroguide4u.com:9000/api/';

export const PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.ss.astro_guide_astro';

export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3';
export const STRIPE_SECRET_KEY = 'sk_test_tR3PYbcVNZZ796tH88S4VQ2u';

export const NO_DATA_FOUND = require('../assets/gif/no-data.gif');

export const FIREBASE_WEB_PUSH_TOKEN = 'BNs7L3V1F_mIpcTXALrRHeAsDBNY4qSzIx7q8VNOnfiWDBPjO86HEFdkf0CPA2yNMl1M7-nrO7tYDIiPpElCwVI'
// export const FIREBASE_WEB_PUSH_TOKEN = 'BAxEGz23E3SHvs4NngXsr7enb6Vpyxybh4FanTtQPYzWpmG6wwlwSn9qeZy0G_fCYz9WYAquhXstzTb0dPlmVKs' // sridix firebase config
export const FIREBASE_CONFIG = {
    apiKey:'AIzaSyDUOLhkvau96w_ClMYHtujA0HQlm6LV8Go',
    authDomain:'astroguide-35db7.firebaseapp.com',
    projectId:'astroguide-35db7',
    storageBucket:'astroguide-35db7.appspot.com',
    messagingSenderId:'170894307366',
    appId:'1:170894307366:web:8fa49f23d0747e554136e7',
    measurementId:'G-10BBVPY06J',

    // sridix firebase config
    // apiKey: "AIzaSyCeLahqGYuFwnplM61F8QDWIrveYMS8b64",
    // authDomain: "defaultproject-87ed8.firebaseapp.com",
    // projectId: "defaultproject-87ed8",
    // storageBucket: "defaultproject-87ed8.appspot.com",
    // messagingSenderId: "57203318058",
    // appId: "1:57203318058:web:7d0220259ba14eb5c29253",

    webPushCertificate:FIREBASE_WEB_PUSH_TOKEN
}

// set localStorage names in constants start --
export const storage = {
    login:'loginUserDetail',
    systemToken:'systemToken',
    language:'language',
    communicate:'communicateObject',
    communicatePlatform:'communicatePlatform',
    
    // chat related
    activeAction:'activeAction',
    chatStartSrv:'chatStartSrv',
    chatStart:'chatStart',
    chatStatus:'chatStatus',

    // call related
    callingClock:'callingClock',
    callStatus:'callStatus',
    callStart:'callStart',

    stripeId:'stripeId',
}
// set localStorage names in constants close --


const userDetail = localStorage?.getItem(storage?.login)?.length > 0 ? JSON?.parse(localStorage?.getItem(storage?.login)) : {}
export const CURR_LANG = localStorage?.getItem(storage?.language)?.length > 0 ? localStorage?.getItem(storage?.language) : 'English'
export const CURR_SHORT_LANG = CURR_LANG == 'Hindi' ? 'hi' : (CURR_LANG == 'Telugu' ? 'te' : 'en')

export const userInfo = userDetail

const Constant = {
    axios,
    moment,
    baseURL,
    apiBaseURL,
    apiURL,
    header:{
        'x-access-token': userDetail?.token?.length > 0 ? userDetail?.token : 'essential'
    },
    body:{},
    apis:{
        cityAll:`${apiURL}city/all`,
        userLogin:`${apiURL}user/login`,
        myProfile:`${apiURL}user/myprofile/`,
        userUpdate:`${apiURL}user/update/`,
        userAdd:`${apiURL}user/add`,
        userVerify:`${apiURL}user/verify`,
        languageAll:`${apiURL}language/all/`,
        countryAll:`${apiURL}country/all/`,
        dashboard:`${apiURL}dashboard/`,
        speciAll:`${apiURL}spec/all/`,
        faqAll:`${apiURL}faq/all`,
        astrologerAll:`${apiURL}astrologer/all/`,
        astrologerUserAll:`${apiURL}astrologer/user/all/`,
        astrologerValues:`${apiURL}astrologer/values/`,
        astrologerSpec:`${apiURL}astrologer/spec/`,
        astrologerById:`${apiURL}astrologer/id/`,
        packageAll:`${apiURL}package/all/`,
        walletTransactionAdd:`${apiURL}wallet/transaction/add/`,
        walletTransactionStatus:`${apiURL}wallet/transaction/status/`,
        settingsFetch:`${apiURL}settings/fetch/`,
        transactionUser:`${apiURL}transaction/user/`,
        addWishlist:`${apiURL}favourite/add`,
        removeWishlist:`${apiURL}favourite/remove/`,
        horoScopeDaily:`${apiURL}horoscope/daily`,
        userKundli:`${apiURL}user/kundli/`,
        userKundliAdd:`${apiURL}user/kundli/add`,
        userKundliUpdate:`${apiURL}user/kundli/update`,
        userKundliDelete:`${apiURL}user/kundli/delete/`,
        userKundliValues:`${apiURL}user/kundli/values/`,
        horoscopeBasicDetails:`${apiURL}horoscope/basic`,
        horoscopeCharts:`${apiURL}horoscope/charts`,
        horoscopePlanet:`${apiURL}horoscope/planet`,
        horoscopeKpPlanet:`${apiURL}horoscope/kp/planet`,
        horoscopeVimdasha:`${apiURL}horoscope/vimdasha`,
        horoscopeYogini:`${apiURL}horoscope/yogini`,
        chatCheck:`${apiURL}chat/check/`,
        chatInitiate:`${apiURL}chat/initiate/`,
        matchhoroscope:`${apiURL}horoscope/ashtakoot`,
        
        // call related start
        callInitiate:`${apiURL}meeting/initiate/`,
        callValidate:`${apiURL}meeting/validate`,
        callReject:`${apiURL}meeting/reject`,
        callEnd:`${apiURL}meeting/end`,
        waitList:`${apiURL}meeting/waitlist`,
        // call related close

        chatHistory:`${apiURL}chat/history/`,
        callCheck:`${apiURL}call/check/`,
        saveRating:`${apiURL}chat/rating/`,
    }
}

export default Constant;