import { React, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight, faInr } from "@fortawesome/free-solid-svg-icons";
import swastiskImg from "../../assets/images/swastic_vector.png";
import starImg from "../../assets/images/star_vector.png";
import omImg from "../../assets/images/om_vector.png";
import walletImg from "../../assets/images/wallet_icon.png";
import { apiCall } from '../../Components/Helper/ApiHelper';
import Constant, { STRIPE_PUBLISHABLE_KEY, storage, userInfo } from "../../config/Constant";
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js/pure';

import 'bootstrap/dist/css/bootstrap.min.css';

import LangTrans from "../../Components/Helper/LangHelper"

// REDUX FILES
import { useDispatch } from "react-redux";
import { userLoginModel } from "../../Redux/Slices/User/LoginModuleSlice";

const Recharge = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [walletFormData, setWalletFormData] = useState({
        amount: '',
        wallet_amount: '',
        gst: '',
        type: '',
        p_id: '',
        net_amount: ''
    })
    const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1)
    const [walletModalVisible, setWalletModalVisible] = useState(false)

    const [packageData, setPackageData] = useState()
    const imgData = [starImg, omImg, swastiskImg];
    const changewalletFormValue = (e) => {
        setWalletFormData((prev) => ({
            ...prev,
            wallet_amount: e?.target?.value,
            [e?.target?.name]: e?.target?.value,
            type: 'CUST',
            p_id: '0',
        }))
    }
    const openWalletAmountModal = () => {
        if (walletFormData?.amount < 50) return
        walletFormData.gst = (walletFormData?.wallet_amount * 18) / 100
        walletFormData.net_amount = parseInt(walletFormData?.wallet_amount) + parseInt(walletFormData?.gst)
        walletFormData.description = `Money added to your wallet on recharge of Rs.${walletFormData?.amount}`
        setWalletFormData({ ...walletFormData })
        setWalletModalVisible(!walletModalVisible)
    }

    const addWalletAmount = async () => {
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)

        walletFormData.successUrl = `${window?.origin}/success`
        walletFormData.cancelUrl = `${window?.origin}/failure`
        setWalletFormData({ ...walletFormData })

        apiCall({ method: 'post', url: Constant?.apis?.walletTransactionAdd, body: walletFormData })?.then((res) => {
            if (res?.status) {
                const result = stripe?.redirectToCheckout({
                    sessionId: res?.transaction_id,
                })
                localStorage?.setItem(storage?.stripeId, res?.id)
                navigate('/paynow', {
                    state: { ...walletFormData, ...res }
                })
            }
        })
    }

    const getPackages = (res) => {
        apiCall({ method: 'get', url: Constant?.apis?.packageAll, isLogin:true }).then((res = {}) => {
            if (res?.status) setPackageData([...res?.data])
            console.log(res)
        })
    }

    useEffect(() => {
        getPackages()
        document.title = 'Recharge : Astroguide'
    }, [])

    return (
        <div className="recharge-page">
            <div className="bg-white">
                <Container className="py-2">
                    <Row>
                        <Col lg='12'>
                            <div className="d-flex gap-2 align-items-center p-1">
                                <FontAwesomeIcon className="primary-color" icon={faHome} />
                                <FontAwesomeIcon className="text-black" icon={faAngleRight} />
                                <label className="pl-1 ff-manrope fs-13 fw-bold">
                                    <LangTrans val="Add Money to Wallet" />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    {
                        userInfo?.name?.length > 0 ? (
                            <Row className="mt-3">
                                <Col lg='8' sm='7' xs='12'>
                                    <Card className="recharge-section border-0">
                                        <Card.Body className="d-flex flex-column gap-3 p-0">
                                            <div className="recharge-input">
                                                <InputGroup className="border-1">
                                                    <Button className="border-right-0 bg-transparent border-0">
                                                        <FontAwesomeIcon icon={faInr} />
                                                    </Button>
                                                    <Form.Control
                                                        placeholder="Enter Custom Amount"
                                                        className="border-0"
                                                        name="amount"
                                                        value={walletFormData?.amount}
                                                        onChange={(e) => changewalletFormValue(e)}
                                                    />
                                                    <Button
                                                        className="border-0 px-4"
                                                        // disabled={packageData?.amount < 50}
                                                        onClick={()=> openWalletAmountModal()}
                                                        // onClick={() => {
                                                        //     {
                                                        //         if (walletFormData?.amount?.length > 0 && walletFormData?.amount < 50) {
                                                        //             openWalletAmountModal()
                                                        //         } else {
                                                        //             // navigate('/home')
                                                        //             openModal();
                                                        //         }
                                                        //     }
                                                        // }}
                                                    >
                                                        <LangTrans val="Recharge Now" />
                                                    </Button>
                                                    {/* <Modal show={showModal} className="d-flex jutify-content-center align-items-center">
                                                        <Container className="text-center p-2">
                                                            <p className="fs-4 font-monospace fw-bold">You have to recharge minimum of Rs. 50</p>
                                                            <button onClick={() => setShowModal(!showModal)} className="btn btn-outline-warning">close</button>
                                                        </Container>
                                                    </Modal> */}
                                                </InputGroup>
                                                <span className="pl-2 text-danger font-weight-bold">
                                                    <LangTrans val={(walletFormData?.amount?.length > 0 && walletFormData?.amount < 50) ? 'You have to recharge minimum of Rs. 50' : ''} />
                                                </span>
                                            </div>
                                            <div className="recharge-packs d-flex align-items-start flex-column px-3 py-3">
                                                <div className="heading">
                                                    <h2 className="ff-playfair fw-bold text-grey-900">
                                                        <LangTrans val="Choose from the available recharge packs." />
                                                    </h2>
                                                </div>
                                                <div>
                                                    <label className="text-grey-500 ff-nunito fs-18 fw-400">
                                                        <LangTrans val="Choose from the available recharge packs." />
                                                    </label>
                                                </div>
                                                <div className="packages pt-3 w-100">
                                                    <Row>
                                                        {
                                                            packageData?.map((row, index) => {
                                                                const img = imgData[index % imgData?.length]
                                                                return (
                                                                    <Col lg='2' sm='3' xs='4' className="mb-2">
                                                                        <Card>
                                                                            <Card.Body className={`p-2 d-flex flex-column justify-content-center ${index === selectedPlanIndex ? 'active' : ''}`}>
                                                                                <div className="price d-flex flex-column gap-1 align-items-center justify-content-center pt-1">
                                                                                    <Image
                                                                                        src={img}
                                                                                        width={45}
                                                                                        height={45}
                                                                                    />
                                                                                    <label className="mt-1 ff-manrope fs-17 fw-bold">{row?.amount}<FontAwesomeIcon icon={faInr} /></label>
                                                                                </div>
                                                                                <Button
                                                                                    className="extra fs-12"
                                                                                    onClick={() => {
                                                                                        setSelectedPlanIndex(index)
                                                                                        const amount = row?.type === 'AMOUNT' ? parseInt(row?.discount) : parseInt(parseInt(row?.amount) * parseInt(row?.discount) / 100)
                                                                                        setWalletFormData({
                                                                                            ...walletFormData,
                                                                                            amount: row?.amount,
                                                                                            type: 'P',
                                                                                            p_id: row?.id,
                                                                                            wallet_amount: parseInt(row?.amount) + amount
                                                                                        })
                                                                                    }}
                                                                                >{row?.discount}{row?.type === 'AMOUNT' ? '₹' : '%'} <LangTrans val="Extra" /></Button>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg='4' sm='5' xs='12'>
                                    <div className='proceed-section mt-3'>
                                        <Card className="wallet">
                                            <Card.Body className="d-flex flex-column rounded-bottom-0">
                                                <div className="heading text-start p-3">
                                                    <label className="ff-manrope fs-16 fw-600 text-grey-900">
                                                        <LangTrans val="Wallet" />
                                                    </label>
                                                </div>
                                                <div className="amount d-flex justify-content-between p-3">
                                                    <div className="image d-flex align-items-center justify-content-center">
                                                        <Image
                                                            src={walletImg}
                                                            width={35}
                                                            height={35}
                                                        />
                                                    </div>
                                                    <div className="balance d-flex flex-column justify-content-end">
                                                        <h3 className="ff-manrope fw-600 text-grey-400 text-end mb-0">₹ {userInfo?.amount ? Number(userInfo?.amount).toFixed(2) : '0.00'}</h3>
                                                        <label className="ff-manrope fs-18 fw-bold text-grey-900">
                                                            <LangTrans val="Available Balance" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <div className="button py-3">
                                            <Button className="d-none">
                                                <LangTrans val="Proceed to Pay" />
                                            </Button>
                                        </div>
                                        <div className="pb-2 d-none">
                                            <label className="text-grey-500 ff-nunito fs-17 fw-400">
                                                <LangTrans val="Money will be added to your Astroguide Wallet." />
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="mt-3">
                                <Col lg='12' sm='7' xs='12'>
                                    <Card className="recharge-section border-0">
                                        <Card.Body className="text-center mh-250-px d-flex justify-content-center align-items-center">
                                            <Button
                                                className="rounded-5 px-5 primary-bg-color primary-border-color text-dark ff-manrope fw-18"
                                                onClick={() => dispatch(userLoginModel(true))}
                                            >
                                                <LangTrans val="Login" />
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
            <Modal
                show={walletModalVisible}
                onHide={() => setWalletModalVisible(!walletModalVisible)}
                backdrop="static"
                keyboard={false}
                centered
                className="recharge-modal"
            >
                <Modal.Header closeButton className="primary-bg-color py-2">
                    <Modal.Title className="w-100 text-center">
                        <LangTrans val="Recharge" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col lg='12' className="text-center">
                            <div className="ff-manrope fs-16 fw-600 text-grey-700">
                                <LangTrans val="We are delighted to provide you with the details of your recharge. Upon recharging with Rs." /> {walletFormData?.amount}, <LangTrans val="you will receive the following balance" /> :
                            </div>
                        </Col>
                        <Col lg='9' className="text-center px-5">
                            <div className="d-flex justify-content-between p-2">
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                                    <LangTrans val="Amount" />
                                </label>
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">{walletFormData?.wallet_amount}</label>
                            </div>
                            <div className="d-flex justify-content-between p-2">
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                                    <LangTrans val="Validity" />
                                </label>
                                <label className="ff-manrope text-inherit fs-15 ls-0-5 bg-success px-2 rounded-3 text-white">
                                    <LangTrans val="Lifetime" />
                                </label>
                            </div>
                            <div className="d-flex justify-content-between p-2">
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                                    <LangTrans val="GST" />(+)
                                </label>
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">{walletFormData?.gst}</label>
                            </div>
                            <div className="d-flex justify-content-between p-2">
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">
                                    <LangTrans val="Total Payment" />
                                </label>
                                <label className="ff-manrope text-inherit text-grey-700 fw-600 ls-0-5">{walletFormData?.net_amount}</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <div className="button p-2">
                                <Button
                                    onClick={() => addWalletAmount()}
                                    className="recharge-modal-btn"
                                >
                                    <LangTrans val="Proceed to Pay" />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Recharge;